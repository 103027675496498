import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCB-jqrfJvwhBKRk3_LifXsaFx4W6OUmck",
  authDomain: "solitaireweb-fb759.firebaseapp.com",
  projectId: "solitaireweb-fb759",
  storageBucket: "solitaireweb-fb759.appspot.com",
  messagingSenderId: "724952011598",
  appId: "1:724952011598:web:03631f75b360092db4841f",
  measurementId: "G-T0YY35HYX9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

window.logEventToAnalytics = function (eventName, eventParams) {
  // Log the event to Firebase Analytics
  logEvent(analytics, eventName, eventParams);

  console.log(
    "From Javascript: Event logged to Firebase Analytics: " +
    eventName +
    " with params: " +
    eventParams +
    " " +
    JSON.stringify(eventParams)
  );
};


// Code that can be called by the Unity games
window.unityLogAnalyticsInterface = function (msg) {
  console.log("From Javascript: Unity event to send: " + msg);
  // Parse the event name from the message - They're separated from the first comma
  const eventName = msg.split(",")[0];
  // Parse everything after first comma as json
  const eventParams = JSON.parse(msg.substring(msg.indexOf(",") + 1));

  console.log(
    "From Javascript: Calling window.logEventToAnalytics. Type of window.logEventToAnalytics: " +
    typeof window.logEventToAnalytics
  );
  window.logEventToAnalytics(eventName, eventParams);
};


logEvent(analytics, "firebase_up");
console.log(
  "Firebase Analytics Initialized. Type of window.logEventToAnalytics: " +
  typeof window.logEventToAnalytics
);

