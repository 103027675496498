import React from "react";
import "../index.css";

function Loading({ loadingProgression }) {
  return (
    <>
      <img
        src="/PCAssets/img/Puzzlecats2023Logo.png"
        alt="puzzlecats-logo"
        height="200"
      />
      <div id="unity-progress-bar-empty">
        <div
          id="unity-progress-bar-full"
          style={{ width: 100 * loadingProgression + "%" }}
        />
      </div>
    </>
  );
}

export default Loading;
