import React, { useSyncExternalStore } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import "./index.css";
import "./firebase";
import UnityFooter from "./components/UnityFooter.jsx";
import Loading from "./components/Loading.jsx";
import BottomContent from "./components/BottomContent";
import RightSide from "./components/RightSide";

function windowResizeCallback(callback) {
  window.addEventListener('resize', callback);
  return () => window.removeEventListener('resize', callback);
}

function windowHeightSnapshot() {
  return window.innerHeight;
}

function App() {
  const { unityProvider, loadingProgression, isLoaded, requestFullscreen } = useUnityContext({
    loaderUrl: "./Build/defaultbuild.loader.js",
    dataUrl: "./Build/defaultbuild.data",
    frameworkUrl: "./Build/defaultbuild.framework.js",
    codeUrl: "./Build/defaultbuild.wasm",
    streamingAssetsUrl: "./StreamingAssets",
    companyName: "Solitaire Games Free",
    productName: "Solitaire",
    productVersion: "5.5",
  });

  const windowHeight = useSyncExternalStore(windowResizeCallback, windowHeightSnapshot);

  if (isLoaded) {
    document.body.style.backgroundColor = "rgb(22, 75, 25)";
  }
  return (
    <>
      <div className="container p-0 m-0">
        <div className="row">
          <div className="col-sm-10"
          >
            <div style={{ minHeight: windowHeight * 0.9, width: "100%", display: isLoaded ? "none" : "block" }}>
              <Loading loadingProgression={loadingProgression} />
            </div>
            <Unity id="canvas2"
              unityProvider={unityProvider}
              style={{ height: windowHeight * 0.9, width: "100%", display: isLoaded ? "block" : "none" }}
            />

            <UnityFooter isLoaded={isLoaded} requestFullscreen={requestFullscreen} />
          </div>
          <div className="col-sm-2">
            <RightSide />
          </div>
        </div>
      </div>

      <BottomContent />
    </>
  );
}

export default App;
