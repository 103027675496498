import React from "react";
import "../index.css";

function BottomContent() {
  return (
    <>
      <div style={{ color: "white" }} className="mt-auto">
        Proudly brought to you by
        <img
          src="/PCAssets/img/Puzzlecats2023Logo.png"
          alt="puzzlecats-logo"
          height="150"
        />
      </div>
    </>
  );
}

export default BottomContent;
