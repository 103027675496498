import React from "react";
import "../index.css";

function RightSide() {
  return (
    <>
      <div style={{ textAlign: "center", color: "white" }}>
        <img src="/TemplateData/favicon.png" alt="game-logo" height="200" />
        <h3>Solitaire Cats!</h3>
      </div>
    </>
  );
}

export default RightSide;
