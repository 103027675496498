import React from "react";
import "../index.css";

function UnityFooter({isLoaded, requestFullscreen}) {
  return (
    <>
      <div id="unity-footer">
        <div id="unity-fullscreen-button" style={{display: isLoaded ? "block" : "none"}} 
          onClick={() => requestFullscreen(true)}
        />
        <div id="unity-build-title" style={{ color: "white" }}>
          Solitaire Cats by Puzzlecats with ❤️
        </div>
        <a
          href="https://smart.link/m5pi9cofcb73u"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="PCAssets/img/Appstore.webp"
            alt="Download on the App Store"
            width="150"
            height="50"
          />
        </a>

        <a
          href="https://smart.link/m5pi9cofcb73u"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/PCAssets/img/Playstore.webp"
            alt="Get it on Google Play"
            width="150"
            height="50"
          />
        </a>
      </div>
    </>
  );
}

export default UnityFooter;
